import { Link } from "gatsby"
import styled from "styled-components"
import Image from "gatsby-plugin-sanity-image"
import { mq, typography } from "styles"

export const Container = styled.div`
  padding: 64px 8.33%;
  ${mq.minWidth("md")} {
    padding: 80px 6.25%;
  }
  ${mq.minWidth("lg")} {
    padding: 0;
  }
`

export const SectionTitle = styled.h2<{ $color: string }>`
  ${typography.blogHeader};
  color: ${({ $color }) => $color};
`

export const ContentContainer = styled.div`
  ${mq.minWidth("md")} {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  grid-gap: 28px;
`

export const ImgContainer = styled.div`
  padding-bottom: 100%;
  margin-bottom: 16px;
  position: relative;
  ${mq.minWidth("md")} {
    margin-bottom: 0;
  }
  & > div {
    padding-bottom: 0 !important;
  }
`

export const Img = styled(Image)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
`

export const Content = styled.div``

export const TagContainer = styled.div`
  margin-bottom: 12px;
  ${mq.minWidth("md")} {
    margin-bottom: 16px;
  }
`

export const PostTitle = styled.h3`
  ${typography.h2};
  color: ${({ theme }) => theme.dark.text?.primary?.opacityHex};
  margin-bottom: 8px;
  ${mq.minWidth("md")} {
    margin-bottom: 12px;
  }
  a {
    color: ${({ theme }) => theme.dark.text?.primary?.opacityHex};
    text-decoration: none;
  }
`

export const Blurb = styled.p`
  color: ${({ theme }) => theme.dark.text?.primary?.opacityHex};
  margin-bottom: 16px;
`

export const Cta = styled(Link)`
  ${typography.body};
  ${typography.defaultLink};
  display: inline-block;
`

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  ${mq.minWidth("md")} {
    margin-bottom: 24px;
  }
`
