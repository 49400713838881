import styled from "styled-components"
import { mq, typography } from "styles"

export const Container = styled.div`
  background: ${({ theme }) => theme.dark.canvas?.primary?.opacityHex};
  display: grid;
  grid-template-areas:
    "featuredPost"
    "sidebar"
    "categories";
  ${mq.minWidth("lg")} {
    grid-template-areas:
      "featuredPost sidebar"
      "categories sidebar";
    grid-template-columns: 4fr minmax(330px, 1fr);
  }
`

export const Featured = styled.div`
  padding: ${24 - 60}px 0 0;
  ${mq.minWidth("md")} {
    padding: ${40 - 60}px 0 0;
  }
  ${mq.minWidth("lg")} {
    padding: ${80 - 60}px 6.11vw 96px;
  }
  grid-area: featuredPost;
`

export const Categories = styled.div`
  ${mq.minWidth("lg")} {
    padding: 0 6.11vw;
  }
  grid-area: categories;
`

export const Sidebar = styled.div`
  padding: 48px 0;
  ${mq.minWidth("md")} {
    padding: 64px 0;
  }
  ${mq.minWidth("lg")} {
    padding: 54px 26px;
  }
  background: ${({ theme }) => theme.dark.canvas?.secondary?.opacityHex};
  grid-area: sidebar;
`

export const SidebarTitle = styled.h2`
  ${typography.blogHeader};
  margin-bottom: 16px;
  padding: 0 8.33vw;
  ${mq.minWidth("md")} {
    margin-bottom: 24px;
    padding: 0 6.25vw;
  }
  ${mq.minWidth("lg")} {
    padding: 0;
  }
  color: ${({ theme }) => theme.dark.text?.primary?.opacityHex};
`
