import { Link } from "gatsby"
import { IGatsbyImageDataWithDesc } from "typings/modules"
import React from "react"
import { Container, Image, Content, PostTitle } from "./SidebarPost.styles"
import { SanityBlogTag } from "typings/graphql"
import { BlogTag } from "components/UI/BlogTag/BlogTag"

type Props = {
  tag: SanityBlogTag
  image: IGatsbyImageDataWithDesc
  postTitle: string
  color: string
  link: string
}

export const SidebarPost = ({ tag, image, postTitle, color, link }: Props) => {
  const altDescription = image?.description || `Image for ${postTitle}`
  return (
    <Container>
      <Link to={link}>
        <Image image={image?.gatsbyImage} alt={altDescription} />
      </Link>
      <Content>
        {tag && <BlogTag tag={tag} color={color} />}
        <PostTitle>
          <Link to={link}>{postTitle}</Link>
        </PostTitle>
      </Content>
    </Container>
  )
}
