import { Carousel } from "components/UI/Carousel/Carousel"
import styled from "styled-components"

export const StyledCarousel = styled(Carousel)`
  width: 100vw;
  .slick-slide {
    padding: 0 8.33vw;
  }
`

export const TabletPosts = styled.div`
  width: 100vw;
  overflow-x: scroll;
  display: flex;
  flex-direction: row;
  justify-content: normal;
  position: relative;
  & > div {
    margin-right: 2vw;
    flex-shrink: 0;
    width: 61.9vw;
    padding-right: 7vw;
    &:first-of-type {
      padding-left: 6.25vw;
    }
  }
`
