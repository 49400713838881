import styled from "styled-components"
import { Carousel } from "components/UI/Carousel/Carousel"
import { mq } from "styles"
import { PostTitle } from "components/BlogCategory/BlogCategory.styles"
import { rgba } from "polished"

export const Container = styled.div`
  ${mq.minWidth("lg")} {
    padding-bottom: 96px;
  }
`

export const RecentPosts = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 30px;
  ${mq.minWidth("lg")} {
    margin-top: 60px;
    ${PostTitle} {
      margin-bottom: 0;
    }
  }
`

export const CarouselContainer = styled.div<{ $color: string }>`
  padding: 32px 0;
  ${mq.minWidth("md")} {
    padding: 0;
  }
  border-top: 1px solid ${({ $color }) => $color};
  border-bottom: 1px solid ${({ $color }) => $color};
`

export const StyledCarousel = styled(Carousel)`
  width: 100vw;
  .slick-slide {
    padding: 0 8.33vw;
  }
`

export const TabletPosts = styled.div<{ $color: string }>`
  width: 100vw;
  overflow-x: scroll;
  display: flex;
  flex-direction: row;
  justify-content: normal;
  position: relative;
  border-top: 1px solid ${({ $color }) => $color};
  border-bottom: 1px solid ${({ $color }) => $color};
  padding: 48px 0;
  & > div {
    margin-right: 2vw;
    flex-shrink: 0;
    width: 61.9vw;
    padding-right: 7vw;
    &:first-of-type {
      padding-left: 6.25vw;
    }
  }
`
