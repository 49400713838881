import React from "react"
import { SanityBlogCategoryList } from "typings/graphql"
import { BlogCategoryList } from "./BlogCategoryList"

export const SanityBlogCategoryListBlock = ({
  fields,
}: {
  fields: SanityBlogCategoryList
}) => (
  <BlogCategoryList
    featuredPost={fields.featuredPost}
    categories={fields.categories}
    sidebarTitle={fields.sidebarTitle}
    sidebarPosts={fields.sidebarPosts}
  />
)

export default SanityBlogCategoryListBlock
