import { BlogTag } from "components/UI/BlogTag/BlogTag"
import { Link } from "gatsby"
import React from "react"
import { SanityBlogTag, SanityImage } from "typings/graphql"
import {
  SectionTitle,
  Container,
  Img,
  ImgContainer,
  Content,
  PostTitle,
  Blurb,
  Cta,
  TitleContainer,
  ContentContainer,
  TagContainer,
} from "./FeaturedPost.styles"

type Props = {
  sectionTitle: string
  tag: SanityBlogTag
  image: SanityImage
  postTitle: string
  blurb: string
  postLink: string
  categoryLink?: string
  color: string
}

export const FeaturedPost = ({
  sectionTitle,
  tag,
  image,
  postTitle,
  blurb,
  postLink,
  categoryLink,
  color,
}: Props) => {
  const altDescription = image?.asset?.description || `Image for ${postTitle}`
  return (
    <Container>
      <TitleContainer>
        <SectionTitle $color={color}>{sectionTitle}</SectionTitle>
        {categoryLink && <Cta to={categoryLink}>See All</Cta>}
      </TitleContainer>
      <ContentContainer>
        <Link to={postLink}>
          <ImgContainer>
            <Img alt={altDescription} {...image} />
          </ImgContainer>
        </Link>
        <Content>
          {tag && (
            <TagContainer>
              <BlogTag color={color} tag={tag} />
            </TagContainer>
          )}
          <PostTitle>
            <Link to={postLink}>{postTitle}</Link>
          </PostTitle>
          <Blurb>{blurb}</Blurb>
          <Cta to={postLink}>Read more</Cta>
        </Content>
      </ContentContainer>
    </Container>
  )
}
