import React, { useContext, useEffect, useState } from "react"
import { FeaturedPost } from "components/BlogCategoryList/FeaturedPost"
import { Query, SanityBlogCategoryItem, SanityBlogPost } from "typings/graphql"
import { graphql, useStaticQuery } from "gatsby"
import {
  CarouselContainer,
  Container,
  RecentPosts,
  StyledCarousel,
  TabletPosts,
} from "./BlogCategoryItem.styles"
import { BlogCategoryPost } from "components/BlogCategory/BlogCategoryPost"
import { useCommonMediaQuery } from "hooks/useMediaQuery"
import { SidebarPost } from "./SidebarPost"
import { blogCatPath, getCategory } from "utils/blogUtils"
import { ThemeContext, ThemeProvider } from "styled-components"
import { getImage } from "utils/imageUtils"
import { cleanUrlSlashes } from "utils/stringUtils"

type Props = {
  category: SanityBlogCategoryItem
}

const query = graphql`
  query {
    allSanityBlogPost {
      edges {
        node {
          id
          title
          tags {
            title
            path {
              current
            }
          }
          path {
            current
          }
          image {
            asset {
              gatsbyImageData(width: 960, fit: FILLMAX, placeholder: NONE)
            }
          }
          primaryCategory {
            title
            path {
              current
            }
          }
          secondaryCategories {
            title
          }
        }
      }
    }
  }
`

export const BlogCategoryItem = ({ category }: Props) => {
  const themeContext = useContext(ThemeContext)
  const { isMobile, isTablet, isDesktop } = useCommonMediaQuery()
  const [recentPosts, setRecentPosts] = useState<SanityBlogPost[]>([])
  const { allSanityBlogPost } = useStaticQuery<Query>(query)
  const featuredPost = category.featuredPost
  const categoryColor = category.color?.opacityHex
  const categorySlug = getCategory(featuredPost)
  useEffect(() => {
    const posts = allSanityBlogPost.edges
      .filter(
        edge =>
          edge.node.primaryCategory?.title === category.title ||
          edge.node.secondaryCategories?.some(
            cat => cat.title === category.title
          )
      )
      .map(post => post.node)
      .sort((a, b) => b._createdAt - a._createdAt)
      .filter(post => post.id !== featuredPost.id)
      .slice(0, 3)
    setRecentPosts([...posts])
  }, [category.title])
  return (
    <Container>
      <FeaturedPost
        sectionTitle={category.title}
        image={featuredPost?.image}
        tag={featuredPost?.tags[0]}
        postTitle={featuredPost?.title}
        blurb={featuredPost?.blurb}
        postLink={blogCatPath(featuredPost)}
        categoryLink={cleanUrlSlashes(`/blog/${categorySlug}/`)}
        color={categoryColor}
      />
      {isDesktop && (
        <RecentPosts>
          {recentPosts.map((post, i) => (
            <RecentPost
              key={`d-category-post-${i}`}
              color={categoryColor}
              post={post}
            />
          ))}
        </RecentPosts>
      )}
      {isTablet && (
        <TabletPosts $color={categoryColor}>
          {recentPosts.map((post, i) => (
            <Post
              key={`t-category-post-${i}`}
              post={post}
              color={categoryColor}
            />
          ))}
        </TabletPosts>
      )}
      {isMobile && (
        <ThemeProvider theme={{ localTheme: themeContext["dark"] }}>
          <CarouselContainer $color={categoryColor}>
            <StyledCarousel>
              {recentPosts.map((post, i) => (
                <Post
                  key={`m-category-post-${i}`}
                  post={post}
                  color={categoryColor}
                />
              ))}
            </StyledCarousel>
          </CarouselContainer>
        </ThemeProvider>
      )}
    </Container>
  )
}

const Post = ({ post, color }: { post: SanityBlogPost; color: string }) => (
  <SidebarPost
    tag={post.tags[0]}
    image={getImage(post.image)}
    color={color}
    postTitle={post.title}
    link={blogCatPath(post)}
  />
)

type RecentPostProps = {
  post: SanityBlogPost
  color: string
}

const RecentPost = ({ post, color }: RecentPostProps) => (
  <BlogCategoryPost
    title={post.title}
    link={blogCatPath(post)}
    image={getImage(post.image)}
    tag={post.tags[0]}
    tagColor={color}
  />
)
