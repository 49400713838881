import { SidebarPost } from "components/BlogCategoryList/SidebarPost"
import { useCommonMediaQuery } from "hooks/useMediaQuery"
import React, { useContext } from "react"
import { ThemeContext, ThemeProvider } from "styled-components"
import { SanityBlogPost } from "typings/graphql"
import { blogCatPath } from "utils/blogUtils"
import { getImage } from "utils/imageUtils"
import { StyledCarousel, TabletPosts } from "./SidebarPosts.styles"

type Props = {
  posts: SanityBlogPost[]
}

const Post = ({ post }: { post: SanityBlogPost }) => (
  <SidebarPost
    image={getImage(post.image)}
    link={blogCatPath(post)}
    tag={post.tags[0]}
    postTitle={post.title}
    color={post.primaryCategory.color?.opacityHex}
  />
)

export const SidebarPosts = ({ posts }: Props) => {
  const themeContext = useContext(ThemeContext)
  const { isMobile, isTablet, isDesktop } = useCommonMediaQuery()
  return (
    <>
      {isMobile && (
        <ThemeProvider theme={{ localTheme: themeContext["dark"] }}>
          <StyledCarousel>
            {posts.map((post, i) => (
              <Post key={`d-sidebar-post-${i}`} post={post} />
            ))}
          </StyledCarousel>
        </ThemeProvider>
      )}
      {isTablet && (
        <TabletPosts>
          {posts.map((post, i) => (
            <Post key={`t-sidebar-post-${i}`} post={post} />
          ))}
        </TabletPosts>
      )}
      {isDesktop &&
        posts.map((post, i) => (
          <Post key={`d-sidebar-post-${i}`} post={post} />
        ))}
    </>
  )
}
