import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { mq, typography } from "styles"
import { rgba } from "polished"

export const Container = styled.div`
  display: grid;
  grid-template-columns: 90px 1fr;
  align-items: flex-start;
  grid-gap: 16px;
  ${mq.minWidth("md")} {
    grid-template-columns: 160px 1fr;
  }
  ${mq.minWidth("lg")} {
    grid-template-columns: 90px 1fr;
    margin-bottom: 16px;
  }

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.dark?.text?.primary?.opacityHex};
    transition: all 0.3s ease;
  }

  &:hover a {
    color: ${({ theme }) => rgba(theme.dark?.text?.primary?.opacityHex, 0.72)};
  }
`

export const Image = styled(GatsbyImage)`
  padding-bottom: 100%;
  display: block !important;
  & > div {
    position: absolute;
    top: 0;
    left: 0;
  }
`

export const Content = styled.div``

export const PostTitle = styled.p`
  ${typography.bodySmall};
  font-size: 16px;
  color: ${({ theme }) => theme.dark.text?.primary?.opacityHex};
`
