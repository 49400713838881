import React from "react"
import { BlogCategoryItem } from "components/BlogCategoryList/BlogCategoryItem"
import { FeaturedPost } from "components/BlogCategoryList/FeaturedPost"
import { SidebarPosts } from "components/BlogCategoryList/SidebarPosts"
import { SanityBlogCategoryList } from "typings/graphql"
import { blogCatPath } from "utils/blogUtils"
import {
  Container,
  Featured,
  Categories,
  Sidebar,
  SidebarTitle,
} from "./BlogCategoryList.styles"

export const BlogCategoryList = (props: Partial<SanityBlogCategoryList>) => {
  const featuredPost = props.featuredPost
  return (
    <Container>
      <Featured>
        <FeaturedPost
          sectionTitle="Featured Post"
          image={featuredPost.image}
          tag={featuredPost.tags[0]}
          postTitle={featuredPost.title}
          blurb={featuredPost.blurb}
          postLink={blogCatPath(featuredPost)}
          color={featuredPost.primaryCategory.color?.opacityHex}
        />
      </Featured>
      <Categories>
        {props.categories.map(
          category =>
            category.featuredPost && (
              <BlogCategoryItem key={category.id} category={category} />
            )
        )}
      </Categories>
      <Sidebar>
        <SidebarTitle>{props.sidebarTitle}</SidebarTitle>
        <SidebarPosts posts={props.sidebarPosts} />
      </Sidebar>
    </Container>
  )
}
